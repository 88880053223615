(function ($) {
  $(document).ready(function () {
    // new Luminous(document.querySelector('.simple-demo'));

    $("a.lumi").on("click", function (e) {
      e.preventDefault();
    });

    $(".no-link").on("click", function (e) {
      $(this).find("> a").attr("href", "javascript:void(0)");
      $(this).addClass("is-active");
    });

    if ($(".lumi").length) {
      var options = {
        // Prefix for generated element class names (e.g. `my-ns` will
        // result in classes such as `my-ns-lightbox`. Default `lum-`
        // prefixed classes will always be added as well.
        namespace: null,
        // Which attribute to pull the lightbox image source from.
        sourceAttribute: "href",
        // Captions can be a literal string, or a function that receives the Luminous instance's trigger element as an argument and returns a string. Supports HTML, so use caution when dealing with user input.

        // The event to listen to on the _trigger_ element: triggers opening.
        openTrigger: "click",
        // The event to listen to on the _lightbox_ element: triggers closing.
        closeTrigger: "click",
        // Allow closing by pressing escape.
        closeWithEscape: true,
        // Automatically close when the page is scrolled.
        closeOnScroll: false,
        // A selector defining what to append the lightbox element to.
        appendToSelector: "body",
        // If present (and a function), this will be called
        // whenever the lightbox is opened.
        onOpen: null,
        // If present (and a function), this will be called
        // whenever the lightbox is closed.
        onClose: null,
        // When true, adds the `imgix-fluid` class to the `img`
        // inside the lightbox. See https://github.com/imgix/imgix.js
        // for more information.
        includeImgixJSClass: true,
        // Add base styles to the page. See the "Theming"
        // section of README.md for more information.
        injectBaseStyles: true,
      };
    }

    if ($(".lumi").length == 1) {
      new Luminous(document.querySelector(".lumi"), options);
    } else if ($(".lumi").length > 1) {
      new LuminousGallery(document.querySelectorAll(".lumi"), {});
    }

    if ($("#slider-quiz").length) {
      $("#slider-quiz").slick({
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: "progressive",
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnDotsHover: true,
        adaptiveHeight: true,
        prevArrow: '<i class="flaticon-arrow-thin arrow-prev"></i>',
        nextArrow: '<i class="flaticon-arrow-thin arrow-next"></i>',
      });
    }

    if ($("#slider-gallery").length) {
      $("#slider-gallery").slick({
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: "progressive",
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnDotsHover: true,
        adaptiveHeight: true,
        prevArrow: '<i class="flaticon-arrow-thin arrow-prev"></i>',
        nextArrow: '<i class="flaticon-arrow-thin arrow-next"></i>',
      });
    }

    var $accordion = new Foundation.Accordion($(".accordion__tabak-fragen"), {
      slideSpeed: 300,
    });

    var $accordion = new Foundation.Accordion($(".accordion__faq"), {
      slideSpeed: 300,
    });

    // Search form
    const $searchFieldWrapper = $(".search__field__wrapper");
    const $searchField = $(".search__field");
    const $icons = $(".search__field__wrapper > i");
    const $iconClose = $(".search__field__wrapper > .flaticon-close");
    const $searchIcon = $(".search__icon");
    const $navigation = $(".menu__container > .menu-wrapper");
    const $cart = $(".cart-symbol");
    const $cartIsVisible = false;

    $searchIcon.on("click", function (e) {
      $(this).hide();
      $navigation.hide();
      $cart.removeClass("is-active");
      $searchFieldWrapper.show();
      e.stopPropagation();

      $iconClose.on("click", function () {
        $navigation.show();
        $cart.addClass("is-active");
        $searchIcon.show();
        $searchFieldWrapper.hide();
      });
    });

    $searchField.on("focus", function () {
      $icons.addClass("is-active");
    });

    $searchField.on("focusout", function () {
      $icons.removeClass("is-active");
    });

    // Tabak-Fragen Overlay
    const $showButton = $(".overlay-open");
    const $closeButton = $(".overlay-close");
    const $page = $("#page");

    let $activeSlide;
    $showButton.on("click touch", function (e) {
      $activeSlide = $(".slide.slick-active > .slide__overlay");
      e.preventDefault();
      $activeSlide.insertAfter("body");
      $page.addClass("overlay-active");
      $activeSlide.addClass("is-active");
    });

    $closeButton.on("click touch", function (e) {
      $activeSlide = $(".slide__overlay.is-active");
      let $originalSpot = $(".slide.slick-active > .slide__button");
      e.preventDefault();
      $activeSlide.insertAfter($originalSpot);
      $page.removeClass("overlay-active");
      $activeSlide.removeClass("is-active");
    });
  });

  // menu toggle
  const $menuOpenToggle = $(".toggle-menu");
  const $toggle = $(".mobile-menu-switch");
  const $menuCloseToggle = $(".mobile-menu-close-icon");
  const $mobileMenu = $(".mobile-menu");

  $menuOpenToggle.on("click touch", function () {
    $mobileMenu.toggleClass("is-active");
  });

  $menuCloseToggle.on("click touch", function () {
    $mobileMenu.removeClass("is-active");
  });
})(jQuery);
